@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Satoshi&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@500&display=swap");

@import url('https://fonts.googleapis.com/css2?  family=Montserrat:ital,wght@0,100;0,200;1,100;1,200&display=swap');



body {
    font-family: "Roboto", sans-serif;
}

/* h1 {
    font-family: "Satoshi", sans-serif;
} */